import { version } from './version';

export const environment = {
  production: true,
  local: false,
  version,
  origin: 'https://cds-apps-harvester-qa.hi5.platform.navify.com',
  firebase: {
    apiKey: 'AIzaSyDJptA3tdS1qxdo07tLSFXlw8D2jKFG5UM',
    authDomain: 'harvester-qa-env.firebaseapp.com',
    databaseURL: 'https://harvester-qa-env.firebaseio.com',
    projectId: 'harvester-qa-env',
    storageBucket: 'harvester-qa-env.appspot.com',
    messagingSenderId: '909831186320',
    appId: '1:909831186320:web:5f3afda22ba42576a47515',
    measurementId: 'G-GJ8E73LSYC',
    region: 'europe-west1'
  },
  projectId: 'harvester-qa-env',
  stackdriver: {
    errorsReporter: {
      key: 'AIzaSyDPoOwbG-b2FJw3rZQH4IUEMZ4t6TtR7hs',
      disabled: false,
      bypass: false
    }
  },
  redirects: {
    user: 'guidelines',
    anonymous: 'auth'
  },
  okta: {
    orgUrl: 'https://rocherapid-test.okta.com',
    clientId: '0oa8tqtkrqRGDSHqz297',
    customTokenUrl:
      'https://europe-west1-harvester-qa-env.cloudfunctions.net/okta-customToken',
    issuerPath: '/oauth2/default'
  },
  auth: {
    oktaIsEnabled: true
  },
  gdlApps: {
    NTB: {
      url: 'https://su-appsdemo.appsdemo-tumorboard.hi5.platform.navify.com',
      api: {
        guidelines: '/cds-gdl-guidelines-service/api/v2/guidelines'
      },
      tenant: 'su-appsdemo',
      exportOptions: {
        keyName: 'harvesterId',
        version: '3',
        addRules: false
      }
    },
    NCH: {
      url: 'https://su-appsdemo.appsdemo-nch.hi5.clinicalhub.platform.navify.com',
      api: {
        guidelines: '/guidelines-service-noh/api/v3/guidelines'
      },
      tenant: 'su-appsdemo',
      exportOptions: {
        version: '3',
        addRules: false
      }
    }
  },
  autotraversal: {
    version: '2.1'
  }
};
